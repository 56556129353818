import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import BannerProduct from '../components/BannerProduct';
import HorizontalCardProduct from '../components/HorizontalCardProduct';
import './Home.css';
const Home = () => {
  const WhatsappInvite = ({ phoneNumber }) => {
    useEffect(() => {
      const videoElement = document.querySelector('.whatsapp-invite-video');
      if (videoElement) {
        videoElement.play().catch((error) => {
          console.error('Error intentando reproducir el video:', error);
        });
      }
    }, []);

    return (
      <div className="whatsapp-invite-container">
        <a
          href={`https://wa.me/${phoneNumber}`}
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-invite-link"
        >
          ¡Haz clic aquí para contactarnos en WhatsApp!
        </a>
      </div>
    );
  };

  return (
    <main className="home-main">
      <BannerProduct />

<section className="products-section">
  <h2>Bronceado Premium</h2>
  <p>
    Descubre <strong>Spa Bronze Brasileiro</strong>: fórmulas avanzadas para un bronceado duradero y saludable.
  </p>
</section>

      <HorizontalCardProduct
        category="Servicios-Home"
      />
         <div className="extra-links">
        <Link to="/servicios">Ver Bronceados</Link>
      </div>
      <section className="hero-section">
        <h2>¿Listo para lucir tu mejor bronceado?</h2>
        <p>
          En <strong>Spa Bronze Brasileiro</strong> de Medellín te ofrecemos servicios de{' '}
          <strong>bronceado natural</strong>, <strong>bronceado instantáneo</strong> y mucho más. Nuestro
          objetivo es resaltar tu belleza de manera saludable, empleando técnicas avanzadas y productos de la
          mejor calidad. ¡Reserva ahora tu sesión y descubre por qué somos los favoritos en la ciudad!
        </p>
      </section>

      <WhatsappInvite phoneNumber="573013657213" />

      <section className="about-section">
        <h2>Quiénes Somos</h2>
        <p>
          Somos especialistas en el <strong>cuidado integral de la piel</strong>, el bienestar y la{' '}
          <strong>relajación</strong>. Queremos brindarte una experiencia única, llena de confort, seguridad y
          excelentes resultados. Nuestro equipo profesional combina conocimiento, pasión y las últimas tendencias de
          bronceado brasileño para que disfrutes de un tono perfecto y duradero.
        </p>
        <div className="about-images">
          <img
            src="https://res.cloudinary.com/dmck1buzz/image/upload/v1725977857/Spa/npequz1of1m7riecfhiv.webp"
            alt="Imagen representativa"
          />
          <img
            src="https://res.cloudinary.com/dmck1buzz/image/upload/v1725977857/Spa/pp0fuo0wrhyhuubiov3m.webp"
            alt="Equipo de trabajo"
          />
        </div>
      </section>

    
      <section className="products-section">
        <h2>Productos de Bronceado</h2>
        <p>
          En <strong>Spa Bronze Brasileiro</strong> también encontrarás una selección de productos para potenciar
          y prolongar tu bronceado. Desde lociones hidratantes hasta aceites especializados, nuestro equipo te asesorará
          para que obtengas los mejores resultados.
        </p>
      </section>
      <HorizontalCardProduct
        category="Productos-Home"
      />

      <section className="services-section">
        <p>
          Explora tratamientos diseñados para realzar tu belleza y obtener un{' '}
          <strong>bronceado natural</strong>. Utilizamos técnicas avanzadas y productos de calidad que te
          garantizan un acabado uniforme y radiante.
        </p>
      </section>
    </main>
  );
};
Home.propTypes = {
  phoneNumber: PropTypes.string,
};
export default Home;
