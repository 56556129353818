import React from 'react';
import LeftCardProduct from '../components/LeftCardProduct.jsx';
import './Productos.css';

const Productos = () => {
  return (
    <section id="productos" className="productos-section">
      <div className="productos-container">
        <div className="productos-header">
          <div className="productos-badge">Nuestros Productos</div>
          <h2 className="productos-heading">Productos de Spa Bronze</h2>
          <p className="productos-description">
            Descubre nuestra variedad de productos, desde accesorios y ropa hasta artículos exclusivos, diseñados para complementar tu experiencia en Spa Bronze.
          </p>
        </div>
        <div className="vertical-card-container">
          <LeftCardProduct category="Productos" />
        </div>
      </div>
    </section>
  );
};

export default Productos;
