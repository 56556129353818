import React from 'react';
import "./Servicios.css";
import VerticalCardProduct from '../components/VerticalCardProduct';

function Servicios() {
  return (
    <section id="services" className="bronceado-section">
      <div className="bronceado-container">
        <div className="bronceado-text-center">
          <div className="bronceado-badge">Nuestros Servicios</div>
          <h2 className="bronceado-heading">Experiencias de Bronceado Únicas</h2>
          <p className="bronceado-description">
            Descubre una variedad de tratamientos de bronceado diseñados para darte un brillo natural y duradero. Desde sesiones de bronceado con sol hasta técnicas innovadoras, ofrecemos lo mejor para ti.
          </p>
        </div>
        <div className="vertical-card-wrapper">
          <VerticalCardProduct category="Servicios" />
        </div>
      </div>
    </section>
  );
}

export default Servicios;
