import { useContext, useEffect, useRef, useState } from 'react';
import fetchCategoryWiseProduct from '../helpers/fetchCategoryWiseProduct';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import addToCart from '../helpers/addToCart';
import Context from '../context';
import './HorizontalCardProduct.css'; // Asegúrate de importar el nuevo archivo CSS
import displayCOPCurrency from '../helpers/displayCurrency';
const HorizontalCardProduct = ({ category, heading }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const loadingList = new Array(13).fill(null);
    const scrollElement = useRef();
    const { fetchUserAddToCart } = useContext(Context);

    const handleAddToCart = async (e, id) => {
        e.preventDefault(); 
        await addToCart(e, id);
        fetchUserAddToCart();
    };

    const fetchData = async () => {
        setLoading(true);
        const categoryProduct = await fetchCategoryWiseProduct(category);
        setLoading(false);

        if (categoryProduct && categoryProduct.data) {
            setData(categoryProduct.data);
        } else {
            setData([]);
        }
    };

    useEffect(() => {
        fetchData();
    }, [category]);

    const scrollRight = () => {
        scrollElement.current.scrollLeft += 300;
    };

    const scrollLeft = () => {
        scrollElement.current.scrollLeft -= 300;
    };

    return (
        <div className="horizontal-card-container">
            <div className="horizontal-card-wrapper">
                <button className="scroll-button left" onClick={scrollLeft}>
                    <FaAngleLeft />
                </button>
                <button className="scroll-button right" onClick={scrollRight}>
                    <FaAngleRight />
                </button>
                
                <div className="horizontal-card-content" ref={scrollElement}>
                    {loading ? (
                        loadingList.map((_, index) => (
                            <div key={index} className="product-card loading">
                                <div className="product-image-placeholder"></div>
                                <div className="product-info">
                                    <div className="loading-placeholder" style={{ width: '80%', height: '20px' }}></div>
                                    <div className="loading-placeholder" style={{ width: '60%', height: '16px' }}></div>
                                    <div className="loading-placeholder" style={{ width: '40%', height: '16px' }}></div>
                                    <div className="loading-placeholder" style={{ width: '50%', height: '20px' }}></div>
                                </div>
                            </div>
                        ))
                    ) : (
                        data.map((product) => (
                            <div key={product._id} className="product-card">
                                <Link to={`product/${product._id}`} className="product-link">
                                    <div className="h-40 bg-gray-100 flex items-center justify-center rounded-t-md overflow-hidden">
                                        <img 
                                            src={product.productImage[0]} 
                                            alt={product.productName || 'Product Image'} 
                                            className="object-cover h-full w-full transform hover:scale-105 transition-transform duration-300"
                                        />
                                    </div>
                                    <div className="product-info">
                                        <h2 className="product-name">{product.productName}</h2>
                                        <p className="product-brand capitalize">{product.brandName}</p>
                                        <div className="product-prices">
                                            <p className="product-selling-price">{displayCOPCurrency(product.sellingPrice)}</p>
                                            <p className="product-original-price">{displayCOPCurrency(product.price)}</p>
                                        </div>
                                        <div className="product-actions">
                                            <button 
                                                className="add-to-cart-btn" 
                                                onClick={(e) => handleAddToCart(e, product._id)}
                                            >
                                                reserva ahora
                                            </button>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

export default HorizontalCardProduct;
