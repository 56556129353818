import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import fetchCategoryWiseProduct from '../helpers/fetchCategoryWiseProduct';
import './reservar.css';

const Reservar = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchServices = async () => {
      setLoading(true);
      const serviciosHome = await fetchCategoryWiseProduct("Servicios-Home");
      const serviciosVertical = await fetchCategoryWiseProduct("Servicios");
      let combined = [];
      if (serviciosHome && serviciosHome.data) {
        combined = combined.concat(serviciosHome.data);
      }
      if (serviciosVertical && serviciosVertical.data) {
        combined = combined.concat(serviciosVertical.data);
      }
      setServices(combined);
      setLoading(false);
    };
    fetchServices();
  }, []);

  const timeOptions = [
    "08:00", "09:00", "10:00", "11:00", "12:00",
    "13:00", "14:00", "15:00", "16:00", "17:00", "18:00"
  ];

  const handleReservation = () => {
    if (!selectedService || !selectedDate || !selectedTime) {
      alert("Por favor, selecciona un servicio, una fecha y una hora");
      return;
    }
    const reservation = {
      serviceId: selectedService._id,
      productName: selectedService.productName,
      price: selectedService.price,
      date: selectedDate,
      time: selectedTime,
    };
    // Se envía la reserva al carrito (por ejemplo, navegando a '/cart' con el objeto en state)
    navigate('/cart', { state: { reservation } });
  };

  return (
    <div className="reservation-container">
      <h2 className="reservation-title">Reserva tu Servicio</h2>
      <div className="reservation-content">
        <div className="services-list">
          <h3 className="section-heading">Selecciona un servicio</h3>
          {loading ? (
            <p>Cargando servicios...</p>
          ) : (
            <div className="services-cards">
              {services.map(service => (
                <div
                  key={service._id}
                  className={`service-card ${selectedService && selectedService._id === service._id ? 'selected' : ''}`}
                  onClick={() => setSelectedService(service)}
                >
                  <h4 className="service-name">{service.productName}</h4>
                  {service.brandName && <p className="service-brand">{service.brandName}</p>}
                  {service.description && (
                    <p className="service-description">
                      {service.description.substring(0, 50)}...
                    </p>
                  )}
                  <p className="service-price">${service.price.toFixed(2)}</p>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="reservation-form">
          <h3 className="section-heading">Selecciona Fecha y Hora</h3>
          <div className="form-group">
            <label htmlFor="reservation-date">Fecha:</label>
            <input
              type="date"
              id="reservation-date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="reservation-time">Hora:</label>
            <select
              id="reservation-time"
              value={selectedTime}
              onChange={(e) => setSelectedTime(e.target.value)}
              required
            >
              <option value="">Selecciona una hora</option>
              {timeOptions.map(time => (
                <option key={time} value={time}>
                  {time}
                </option>
              ))}
            </select>
          </div>
          <button className="reservation-button" onClick={handleReservation}>
            Realizar Reserva
          </button>
        </div>
      </div>
    </div>
  );
};

export default Reservar;
